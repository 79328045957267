.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.css-pp5p9o-MuiToolbar-root,
.css-s4ztc2-MuiDrawer-docked .MuiDrawer-paper,
.css-1das16k-MuiPaper-root-MuiCard-root,
.MuiPaper-root {
  background-color: #000 !important;
}
.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.css-pp5p9o-MuiToolbar-root {
  border-bottom: 1px solid #8080803b;
}
.css-s4ztc2-MuiDrawer-docked .MuiDrawer-paper {
  border-right: 1px solid #8080803b !important;
}
.css-k23e09-MuiInputBase-input-MuiOutlinedInput-input {
  height: 25px;
}
.css-advny6-MuiPopper-root .MuiPopperUnstyled-root {
  width: 74%;
  left: -92px;
}
.roundaddbutton {
  min-width: auto !important;
  padding: 8px 8px !important;
  border-radius: 50% !important;
  background-color: #0d838b4a;
}

.MuiContainer-root {
  width: 100%;
}

.reportfilters {
  background: #25262b;
  border-radius: 50px;
  padding: 3px;
  margin: 10px;
}

.country-icon {
  height: 13px !important;
}

.reportfiltersd {
  /* background: #25262b;
    border-radius: 50px;
    padding: 8px;
    height: 44px;
    width: 44px;
    margin: 5px; */
}
.menu-icon {
  margin-right: 5px !important;
  height: 15px;
}

.currency-default-icon {
  margin-top: 4px !important;
  color: #085f64;
  float: right !important;
}

.MuiPaper-outlined {
  border: 2px solid #fff !important;
}
.MuiPaper-outlined:hover {
  border: 2px solid #085f64 !important;
  transition: border 1s;
  cursor: pointer;
}
.MuiChip-outlined {
  border: 1px solid #fff !important;
}
.css-13zv9kk-MuiDrawer-docked .MuiDrawer-paper,
.css-e1sb8f-MuiDrawer-docked {
  border-right: 1px solid #8080802e !important;
}
.MuiDivider-root {
  display: none;
}
.MuiGrid-root {
  /* padding-left: 9px !important; */
}
.h-icons {
  position: relative;
  top: 4px;
}

.MuiFormControl-root {
  margin-top: 10px;
}

.css-1fqz65k-MuiList-root {
  background: #000 !important;
}

.css-152q3oc-MuiAvatar-root {
  display: none;
}

.MuiListItemText-secondary {
  font-size: 13px !important;
  margin-top: 2px !important;
}

.MuiButtonBase-root {
  border-radius: 13px;
}

.MuiAlert-standard {
  border: 1px solid #0d7076;
  background: #0d707626 !important;
}

.css-fdw07m-MuiInputBase-root-MuiInput-root:before,
.css-fdw07m-MuiInputBase-root-MuiInput-root:before {
  border-bottom: none !important;
}

.inputamount,
.inputamount:hover,
.inputamount:focus {
  font-size: 35px;
  background: #000;
  color: #fff;
  border: none;
  font-family: inherit;
  outline: none;
  padding: 10px;
  text-align: center !important;
  width: 100%;
}

@media only screen and (max-width: 480px) {
  main {
    padding: 0px !important;
  }
  .css-skngc5-MuiCardHeader-root {
    padding: 15px;
  }
  .MuiPaper-outlined {
    margin-bottom: 10px;
  }

  header {
    display: none !important;
  }

  /* .mobile-header {
    position: fixed;
    width: 90%;
    background: #000;
    z-index: 1000;
    top: -3px;
    border-radius: 0px;
  } */
  /* .nav-bar {
    position: fixed;
    width: 100%;
    bottom: -7px;
    background: rgb(31, 34, 39, 0.75);
    padding: 20px;
    margin: -1px;
    margin-bottom: 2px;
    padding-top: 0px;
    border-radius: 16px;
  } */
  .nav-add-button {
    background: #00897b;
    padding: 6px;
    color: #000;
    border-radius: 25px;
    height: 50px;
    width: 50px;
    top: -15px;
    position: relative;
  }
}

.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.position-relative {
  position: relative;
}
.cursor-pointer {
  cursor: pointer;
}
.py-1 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.py-2 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-3 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.mr-2 {
  margin-right: 10px;
}
.bottom-navigation-transpearnt {
  /* background-color: red !important; */
}

.MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
  background-color: #00897b !important;
}
.MuiDialogActions-root .MuiButtonBase-root.MuiButton-root {
  color: #00897b !important;
}

input[type="color"] {
  appearance: none; /* Remove default styling */
  -webkit-appearance: none; /* Remove default styling for Safari */
  border-radius: 5px; /* Add border-radius for a rounded appearance */
  /* Add padding for better visual appearance */
}

.pr {
  padding-right: "9px !important";
}

@media (min-width: 600px) {
  .negative-margin-40 {
    margin-top: -40px;
  }
}
@media (max-width: 900px) {
  .remove-scroll-lgscreen {
    height: 100vh;
  }
}

.MuiListSubheader-root {
  background-color: #00897bc2 !important;
  border-radius: 10px;
}

.transaction-note {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
}

.icon-img-color {
  filter: invert(37%) sepia(91%) saturate(591%) hue-rotate(130deg)
    brightness(90%) contrast(101%);
}
.icon-img-color-white {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
    brightness(102%) contrast(102%);
}

.Mui-error {
  color: #f44336;
  background: #9e9e9e1a;
  padding: 5px;
  border-radius: 5px;
}

.css-s665k8-MuiDrawer-docked .MuiDrawer-paper {
  margin-top: 80px;
  z-index: 1099;
  width: 215px;
  background: #000;
  color: #fff;
  border-right: none;
  border-right: 1px solid #8080804d;
}

.Toastify__toast--success {
  color: blue !important;
}

.Toastify__toast--success .custom-notification-popup {
  background-color: rgb(31, 34, 39, 0.75);
  border-radius: 16px;
}

.notification-popup .MuiPaper-root {
  background-color: rgb(31, 34, 39, 1) !important;
  border: 1px solid #00897b !important;
  border-radius: 12px !important;
}

.Toastify__toast-theme--light {
  background: #18191d !important;
  color: #fff !important;
}

.css-18rh7f3-MuiModal-root-MuiDrawer-root .MuiPaper-root {
  background-color: #2b2b33 !important;
  border-radius: 0px;
  padding: 16px;
  max-height: 100%;
  min-width: 400px;
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: blue;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: purple;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );

  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);

  --toastify-color-progress-bgo: 0.2;
}

.icon-box-img-p-3 {
  padding: 3px;
}

.field-error {
  border-color: #f44336 !important;
}
.field-error .MuiTypography-root.MuiTypography-body1 {
  color: #f44336 !important;
  background: #9e9e9e1a !important;
  padding: 5px !important;
  border-radius: 5px !important;
}
/* .MuiFormLabel-root.MuiInputLabel-root {
  color: #f44336 !important;
  background: #9e9e9e1a !important;
  padding: 5px !important;
  border-radius: 5px !important;
} */

.select-custom-box {
  border: 1px solid #00987c;
  padding: 4px;
  height: 27px;
  width: 27px;
}

.Toastify__progress-bar--success {
  background: #00897bc2 !important;
}

.Toastify__toast-icon svg {
  fill: #00897bc2 !important;
}

.Toastify__close-button .Toastify__close-button--light svg {
  fill: #ffffff o !important;
}
.object-scale {
  object-fit: scale-down !important;
}

.custom-select-box > .MuiOutlinedInput-input {
  height: 45px !important;
  display: flex !important;
  align-items: center !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorSecondary.MuiInputBase-formControl.custom-select-box {
  height: 60px !important;
}
